import React, { FC } from 'react';

export type IconType = {
  icon: string;
  title?: string;
  description?: string;
  className?: string;
};

const Icon: FC<IconType> = ({
  icon = 'dummy',
  title,
  description,
  className = '',
}: IconType) => {
  const classNames = `icon icon--${icon} ${className}`;
  const role = title ? 'img' : 'presentation';
  const baseId = Math.random().toString(16).slice(-4);
  const labelledby: string[] = [];
  if (title) {
    labelledby.push(`${baseId}-title`);
  }
  if (description) {
    labelledby.push(`${baseId}-desc`);
  }

  return (
    <svg
      className={classNames}
      viewBox="0 0 200 200"
      role={role}
      aria-labelledby=""
    >
      {title && <title id={`${baseId}-title`}>{title}</title>}
      {description && <desc id={`${baseId}-desc`}>{description}</desc>}

      <use xlinkHref={`#icon-${icon}`}></use>
    </svg>
  );
};

export default Icon;
