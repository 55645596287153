/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import Button from '../../../atoms/button/react/Button';
import IconButton from '../../../atoms/icon-button/IconButton';
import { Filter, MapLocation } from '../types';

export type Props = {
  locations?: MapLocation[];
  activeIndex?: number;
  setActiveIndex: (index: number) => void;
  filter?: Filter[];
};

const LocationSlider = ({
  locations,
  activeIndex = 0,
  setActiveIndex,
  filter,
}: Props): JSX.Element => {
  const [active, setActive] = useState(locations?.[activeIndex]);

  useEffect(() => {
    setActive(locations?.[activeIndex]);
  }, [activeIndex]);

  const handlePrev = () => {
    setActiveIndex(
      activeIndex - 1 >= 0 ? activeIndex - 1 : locations?.length ?? 0 - 1,
    );
  };
  const handleNext = () => {
    setActiveIndex(
      activeIndex + 1 < (locations?.length ?? 0) ? activeIndex + 1 : 0,
    );
  };

  const getText = (locationFilter: { [key: string]: string }) => {
    const locKeys = Object.keys(locationFilter);
    return (
      <p>
        {locKeys.map((filterKey, i) => {
          const find = filter?.find(f => f.value === filterKey);
          const number = Number(active?.filter?.[filterKey]);
          return (
            <span key={filterKey}>
              {number} {number > 1 ? find?.tooltipPlural : find?.tooltip}
              {i < locKeys.length - 1 ? <br /> : ''}
            </span>
          );
        })}
      </p>
    );
  };

  return (
    <div className="filter-map__location-slider">
      <IconButton
        onClick={handlePrev}
        icon="chevron-left"
        iconTitle="Vorherigen Ort anzeigen"
        secondary
        className="filter-map__location-button filter-map__location-button--prev"
      />
      {active && (
        <div className="filter-map__location-subgrid">
          {active.name && (
            <h3 className="headline headline--4">{active.name}</h3>
          )}
          {active.filter && getText(active.filter)}
          {active.link?.link && (
            <Button
              text={active.link?.text || 'Zum Programm'}
              link={active.link.link}
              appearance="secondary"
            />
          )}
        </div>
      )}
      <IconButton
        onClick={handleNext}
        icon="chevron-right"
        iconTitle="Nächsten Ort anzeigen"
        secondary
        className="filter-map__location-button filter-map__location-button--next"
      />
    </div>
  );
};

export default LocationSlider;
